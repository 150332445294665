import { render, staticRenderFns } from "./RecordEditor.vue?vue&type=template&id=4df925cb&"
import script from "./RecordEditor.vue?vue&type=script&lang=js&"
export * from "./RecordEditor.vue?vue&type=script&lang=js&"
import style0 from "./RecordEditor.vue?vue&type=style&index=0&id=4df925cb&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports