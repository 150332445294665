<template>
  <div class="core-person-log">
    <record-editor
      class="log-record"

      v-for="(record,i) in records"
      :key="record.id"
      v-model="records[i]"
      :types="types"
      :limit="limit"

      @event-add="addEvent(record, $event)"
      @event-update="updateEvent($event)"
      @event-delete="deleteEvent(record, $event)"
    ></record-editor>
  </div>
</template>

<script>
/*
Este componente recibe en su v-model una lista de RECORDS al log.
Cada RECORD es un objeto con las propiedades "person" y "events"

"person": Objeto persona
"events" es un arreglo de EVENTOS

Ejemplo:
[
  {
    "person": {...},
    "events": [
      {
        "id":
        "type":
        "date":
        "observations":

        "timestamp":
        "author":
      }
    ]
  }
]

Recibe en la propiedad "types" un arreglo de tipos de evento a listar para crear nuevos eventos

types:
[
    {
        "id": 1,
        "singular": "ausente",
        "plural": "ausentes"
    },
    ...
]

*/

import RecordEditor from "./RecordEditor.vue";

export default {
  name: "core-person-log",

  components: {
    RecordEditor,
  },

  props: {
    value: {
      type: Array,
      required: true
    },

    types: {
      type: Array,
      required: true
    },

    limit: {
      required: false,
      default: null
    }
  },

  data() {
    return {
      records: []
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.records = JSON.parse(JSON.stringify(newValue));
      }
    }
  },

  methods: {
    deleteEvent(record, eventIndex) {
      this.$emit("remove", record.events[eventIndex]);

      record.events.splice(eventIndex, 1);
      this.$emit("input", this.records);
    },

    addEvent(record, newEvent) {
      this.$emit(
        "add",
        Object.assign({}, newEvent, { person: record.person.id })
      );

      record.events.push(newEvent);
      this.$emit("input", this.records);
    },

    updateEvent(event) {
      this.$emit("change", event);
      this.$emit("input", this.records);
    }
  }
};
</script>

<style lang="scss">
.core-person-log {
  .log-record {
    padding: 6px 0 6px 6px;
    border-bottom: 1px solid #eee;
  }
}
</style>