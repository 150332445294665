<template>
  <div
    class="log-record-editor"
    :class="{'--empty':!record.events.length}"
  >
    <person-list-item
      class="log-record-person"
      formal
      :person="record.person"
      v-bind="personItemProps"
      @click="editorIsOpen = !editorIsOpen"
    >
      <template #right>
        <button
          v-show="!record.events.length && !editorIsOpen"
          class="ui-button log-quick"
          v-for="(type,i) in quickTypes"
          :key="i"
          v-text="type.singular"
          @click.stop="$emit('event-add', {type:type.id, observations:''}); editorIsOpen = false"
        ></button>

        <ui-icon
          class="icon-toggler"
          :value="editorIsOpen ? 'mdi:close' : (record.events.length ? 'mdi:pencil' : 'mdi:dots-vertical')"
        ></ui-icon>
      </template>
    </person-list-item>

    <div
      class="log-record-body"
      v-show="editorIsOpen"
    >
      <event-editor
        v-if="record.events.length"
        :types="types"
        v-model="record.events[0]"
        @input="$emit('event-update', $event); editorIsOpen = false"
        @delete="$emit('event-delete', 0); editorIsOpen = false"
        @cancel="editorIsOpen = false"
      ></event-editor>

      <event-editor
        v-if="!limit || record.events.length < limit"
        class="log-event-adder"
        :types="types"
        @input="$emit('event-add', $event); editorIsOpen = false"
      ></event-editor>
    </div>
  </div>
</template>

<script>
/*
En RECORD al log de personas es un objeto con las propiedades "person" y "events"

{
  "person": {id, firstName, lastName},
  "events": [
    {id, type, date, observations},
    {id, type, date, observations},
    {id, type, date, observations},
  ]
}

Este componente actualmente solo edita el ULTIMO evento registrado.
Deberia soportar multiples eventos !!!

*/
import EventEditor from "./EventEditor.vue";
import PersonListItem from "@/modules/core/components/Person/ListItem.vue";
import { UiIcon } from "@/modules/ui/components";

export default {
  name: "log-record-editor",
  components: { EventEditor, PersonListItem, UiIcon },

  props: {
    value: {
      type: Object,
      required: false,
      default: null
    },

    types: {
      type: Array,
      required: true
    },

    limit: {
      required: false,
      default: null
    }
  },

  data() {
    return {
      record: null,
      editorIsOpen: false
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newRecord) {
        this.record = JSON.parse(JSON.stringify(newRecord)); //clone
      }
    }

    // Focus textarea on editor open
    // editorIsOpen(newValue) {
    //   if (newValue) {
    //     setTimeout(() => this.$el.querySelector('textarea').focus(), 30);
    //   }
    // }
  },

  computed: {
    quickTypes() {
      return this.types.filter(type => type._quick);
    },

    notQuickTypes() {
      return this.types.filter(type => !type._quick);
    },

    personItemProps() {
      return {
        secondary: this.record.events.length
          ? this.getType(this.record.events[0].type).singular
          : null,

        tertiary: this.record.events.length
          ? this.record.events[0].observations
          : null,

        badge: this.record.events.length && this.record.events[0]._action != null ? '!' : null
      };
    }
  },

  methods: {
    getType(typeId) {
      let found = this.types.find(type => type.id == typeId);
      if (found) {
        return found;
      }

      return {
        id: typeId,
        singular: typeId + "(?)",
        plural: typeId + "(?)"
      };
    }
  }
};
</script>

<style lang="scss">
.log-record-editor {
  .log-record-person {
    .text-secondary {
      font-weight: bold !important;
      font-size: 13px !important;
      color: rgba(0, 0, 0, 0.8) !important;
    }

    .text-tertiary {
      font-size: 13px !important;
    }

    .item-right {
      padding: 0 var(--ui-padding-horizontal);
    }

    .log-quick {
      font-size: 13px;
      margin-right: 12px;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .log-record-body {
    margin: 0 9px 0 56px;
    font-size: 0.9em;

    & > .log-event-editor {
      margin-bottom: 6px;
    }
  }

  .icon-toggler {
    cursor: pointer;
    // color: var(--ui-color-primary);
    color: rgba(0, 0, 0, 0.5);
  }

  // Always expand first child
  // &.--empty:first-child {
  //   .log-event-adder {
  //     display: block;
  //   }

  //   .icon-toggler {
  //     display: none;
  //   }
  // }
}
</style>