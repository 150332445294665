<template>
  <div class="log-event-editor">
    <div class="textarea-container">
      <textarea
        v-model="event.observations"
        :placeholder="$t('CorePersonLogEventEditor.Observations')"
        class="ui-native"
      ></textarea>
    </div>

    <div class="event-button-list">
      <button
        type="button"
        v-for="option in selectOptions"
        :key="option.label"
        class="ui-button"
        :class="{'--main': option.value == event.type}"
        v-text="option.label"
        @mousedown="setEventType(option.value)"
      ></button>
    </div>

    <div
      class="editor-footer"
      v-if="value"
    >
      <ui-item
        class="item-deleter"
        @click="deleteEvent()"
        :text="$t('common.Delete')"
        icon="g:delete"
      ></ui-item>

      <button
        type="button"
        class="ui-button --main"
        @mousedown="accept()"
        v-text="$t('common.Accept')"
      ></button>

      <button
        type="button"
        class="ui-button --cancel"
        @mousedown="cancel()"
        v-text="$t('common.Cancel')"
      ></button>
    </div>
  </div>
</template>

<script>
/*
Este componente recibe en su v-model un EVENTO
{
  "type": null,
  "observations": null
}
*/
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import { UiItem } from "@/modules/ui/components";

export default {
  name: "log-event-editor",
  mixins: [useI18n],
  components: { UiItem },

  props: {
    value: {
      type: Object,
      required: false
    },

    types: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      event: null
    };
  },

  computed: {
    fixedObservations() {
      return !!this.value;
    },

    selectOptions() {
      return this.types.map(typeObj => ({
        label: typeObj.singular,
        value: typeObj.id
      }))
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.event = newValue
          ? JSON.parse(JSON.stringify(newValue))
          : { type: null, observations: null };
      }
    }
  },

  methods: {
    setEventType(newType) {
      this.event.type = newType;
      if (!this.value) {
        this.accept();
        this.event = { type: null, observations: null };
      }
    },

    accept() {
      this.$emit("input", this.event);
    },

    cancel() {
      this.event = this.value ? JSON.parse(JSON.stringify(this.value)) : {};
      this.$emit("cancel");
    },

    deleteEvent() {
      if (confirm(this.$t("CorePersonLogEventEditor.confirm.delete"))) {
        this.$emit("delete");
      }
    },

    getType(typeId) {
      let found = this.types.find(type => type.id == typeId);
      if (found) {
        return found;
      }

      return {
        id: typeId,
        singular: typeId + "(?)",
        plural: typeId + "(?)"
      };
    }
  },

  i18n: {
    en: {
      "CorePersonLogEventEditor.Observations": "Observations",
      "CorePersonLogEventEditor.confirm.delete": "Delete this event ?"
    },

    es: {
      "CorePersonLogEventEditor.Observations": "Observaciones",
      "CorePersonLogEventEditor.confirm.delete": "Eliminar este evento ?"
    }
  }
};
</script>

<style lang="scss">
.log-event-editor {
  .editor-face {
    cursor: pointer;

    padding: 0;
    border-radius: 4px;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
      // background-color: rgba(255,255,255, .6);
    }

    .event-details {
      padding: 8px 12px;

      h3,
      p {
        margin: 0;
        padding: 0;
      }

      h3 {
        font-size: 1em;
        white-space: nowrap;

        .edit-icon {
          font-size: 14px;
          color: #aaa;
          margin-left: 0.5em;
        }
      }

      p {
        font-size: 0.8em;
        opacity: 0.8;
      }
    }

    .delete-icon {
      cursor: pointer;
      color: #999;
      padding: 8px 12px;

      &:hover {
        color: var(--phi-color-warning);
      }
    }
  }

  .event-button-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button {
      flex: 1;
      min-width: 200px;
      margin: 4px 0;
      color: rgba(0, 0, 0, 0.8);

      &.--main {
        color: #fff;
      }
    }
  }

  .textarea-container {
    // margin-right: 8px;
    margin-bottom: 5px;

    textarea {
      display: block;
      font-size: 1em;
      width: 100%;
      height: 3em;
      min-height: 3em;
    }
  }

  .editor-footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: 12px;

    .item-deleter {
      font-size: 0.9em;
      flex: 1;
      margin-right: 40px;
      cursor: pointer;
      color: var(--ui-color-danger);

      --item-icon-width: 36px;


      .item-body {
        padding-left: 4px;
      }

      .item-icon {
        color: var(--ui-color-danger) !important;
      }

      &:hover,
      &:hover .ui-icon {
        color: var(--ui-color-danger) !important;
      }
    }
  }
}
</style>