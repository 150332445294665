<template>
  <div class="person-log-tabbed">
    <ui-tabs
      v-model="filter"
      class="tabs-filters"
    >
      <ui-tab
        value="_pending"
        label="Sin registro"
      ></ui-tab>
      <ui-tab
        v-for="type in types"
        :key="type.id"
        :value="type.id"
        :label="type.plural"
      ></ui-tab>
      <ui-tab
        :value="null"
        label="Todos"
      ></ui-tab>
    </ui-tabs>

    <person-log
      v-model="filteredRecords"
      :types="types"
      :limit="limit"
      v-on="listeners"
    ></person-log>
  </div>
</template>

<script>
import PersonLog from "./Log.vue";
import { UiTabs, UiTab } from "@/modules/ui/components";

export default {
  components: {
    PersonLog,
    UiTabs,
    UiTab
  },

  props: {
    value: {
      type: Array,
      required: true
    },

    types: {
      type: Array,
      required: true
    },

    limit: {
      required: false,
      default: null
    }
  },

  data() {
    return {
      filter: '_pending',//null, // id del tipo a filtrar, o '_pending' para mostrar entradas sin eventos
      records: []
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        this.records = JSON.parse(JSON.stringify(newValue));
      }
    }
  },

  computed: {
    listeners() {
      let retval = {};

      this.$listeners.add && (retval.add = this.$listeners.add);
      this.$listeners.change && (retval.change = this.$listeners.change);
      this.$listeners.remove && (retval.remove = this.$listeners.remove);

      return retval;
    },

    filteredRecords: {
      get() {
        let retval = [];
        for (let i = 0; i < this.records.length; i++) {
          let entry = this.records[i];
          entry._originalIndex = i;

          if (!this.filter) {
            retval.push(entry);
            continue;
          }

          if (this.filter == "_pending" && entry.events.length == 0) {
            retval.push(entry);
            continue;
          }

          if (!entry.events.length) {
            continue;
          }

          for (let k = 0; k < entry.events.length; k++) {
            if (entry.events[k].type == this.filter) {
              retval.push(entry);
              break;
            }
          }
        }

        return retval;
      },

      set(alteredEntries) {
        alteredEntries.forEach(entry => {
          if (typeof entry._originalIndex == "undefined") {
            return;
          }

          this.records[entry._originalIndex] = entry;
          this.records[entry._originalIndex]._originalIndex = undefined;
        });

        this.$emit("input", this.records);
      }
    }
  }
};
</script>

<style lang="scss">
.person-log-tabbed {
  .tabs-filters {
    margin-bottom: 16px;
  }
}
</style>