<template>
  <ui-expand
    class="section-attendance-item"
    position="fixed"
    full-width
    contents-class="expanded-contents"
    toolbar-class="expanded-toolbar"
  >
    <template #trigger>
      <ui-item
        icon="g:assignment_ind"
        :text="text"
      ></ui-item>
    </template>

    <template #header>
      <ui-item
        class="header-item"
        :text="text"
      >
        <template #right>
          <ui-input-date v-model="date"></ui-input-date>

          <div
            class="attendance-notice"
            v-if="helper"
            @click="helper.showPending()"
          >
            <span
              class="ui-badge"
              v-if="helper.pending.length"
              v-text="helper.pending.length"
            ></span>
            <ui-icon
              value="mdi:wifi"
              size="20"
            ></ui-icon>
          </div>
        </template>
      </ui-item>
    </template>

    <template #contents>
      <v3-attendance
        v-if="sectionId"
        context="section"
        :context-id="sectionId"
        :date="date"
        @update:helper="helper = $event"
      ></v3-attendance>
    </template>
  </ui-expand>
</template>

<script>
import { UiItem, UiIcon, UiExpand, UiInputDate } from "@/modules/ui/components";
import V3Attendance from "@/modules/v3/components/V3Attendance/V3Attendance.vue";

export default {
  name: "v3-teacher-dashboard-section-attendance",

  components: {
    UiItem,
    UiIcon,
    UiExpand,
    UiInputDate,
    V3Attendance
  },

  props: {
    sectionId: {
      required: true
    },

    text: {
      type: String,
      required: false,
      default: "Asistencia"
    }
  },

  data() {
    return {
      date: new Date(),
      sessionId: null,
      helper: null
    };
  }
};
</script>

<style lang="scss">
.section-attendance-item {
  .attendance-notice {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ui-badge {
      background-color: var(--ui-color-danger);
      color: #fff;

      position: absolute;
      top: 12px;
      right: 4px;

      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 8px;
      font-weight: bold;
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
  }

  // Hijack header item styles
  .header-item {
    min-height: 48px;

    & > .item-right {
      padding: 0;
      padding-right: 5px; // ARGDSGSD, si esto es menor que 5px el contenedor del expander hace un scroll horizonal
    }

    .ui-input-date {
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent;
      color: #fff;
      font-size: 0.8em;

      .item-body {
        padding-right: 0;
      }
    }
  }

  .expanded-contents {
    background-color: #fff !important;

    .ui-tabs {
      position: sticky;
      top: 48px;
      z-index: 2;

      background-color: var(--ui-color-primary);
      color: #fff;

      .ui-tab-item.selected label {
        color: #fff;
        opacity: 1;
      }

      // tab bottom border
      .ui-tab .ui-tab-item::after {
        background-color: #fff;
        height: 3px;
      }
    }
  }

  .expanded-toolbar {
    background-color: var(--ui-color-primary) !important;
    color: #fff;

    .toolbar-arrow {
      color: #fff !important;
    }
  }
}
</style>