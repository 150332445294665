<template>
  <div class="v3-attendance">
    <log-editor
      :limit="1"
      :types="eventTypes"
      :value="records"
      @add="eventAdded"
      @change="eventChanged"
      @remove="eventRemoved"
    ></log-editor>

    <ui-dialog
      :open.sync="dialogIsOpen"
      title="Sincronizar cambios"
    >
      <template #contents>
        <ui-item
          v-if="pendingCounters.create"
          icon="mdi:account-multiple-plus"
          :text="`${pendingCounters.create} registros por crear`"
        ></ui-item>

        <ui-item
          v-if="pendingCounters.create"
          icon="mdi:account-edit"
          :text="`${pendingCounters.update} registros por actualizar`"
        ></ui-item>

        <ui-item
          v-if="pendingCounters.delete"
          icon="mdi:account-multiple-minus"
          :text="`${pendingCounters.delete} registros por borrar`"
        ></ui-item>
      </template>

      <template #footer>
        <div class="ui-row --tight">
          <button
            type="button"
            class="ui-button --main"
            @click="flush(true)"
          >Guardar cambios</button>

          <button
            type="button"
            class="ui-button --cancel"
            @click="dialogIsOpen = false"
          >Cancelar</button>

          <span style="flex:1"></span>

          <button
            type="button"
            class="ui-button --danger"
            @click="deletePending"
          >Deshacer todo</button>
        </div>
      </template>
    </ui-dialog>
  </div>
</template>

<script>
import useApi from "@/modules/api/mixins/useApi.js";
import apiAttendance from "@/modules/v3/api/attendance.js";

import LogEditor from "@/modules/core/components/Person/Log/LogTabbed.vue";
import { UiItem, UiDialog } from "@/modules/ui/components";

export default {
  name: "v3-attendance",
  mixins: [useApi],
  api: apiAttendance,

  components: {
    LogEditor,
    UiItem,
    UiDialog
  },

  props: {
    context: {
      type: String,
      required: true,
      validator: value => ["section", "session"].indexOf(value) !== -1
    },

    contextId: {
      type: String,
      required: true
    },

    date: {
      required: false,
      default: () => new Date()
    }
  },

  data() {
    return {
      isOnline: true,
      absenceTypes: [],
      people: [],
      absences: [],
      dialogIsOpen: false
    };
  },

  computed: {
    eventTypes() {
      return this.absenceTypes.map(type => ({
        id: type.id,
        singular: type.name,
        plural: type.name,
        _quick: type._quick
      }));
    },

    records() {
      return this.people.map(person => ({
        id: person.id,
        person,
        events: this.absences
          .filter(abs => abs.person == person.id && abs._action != "delete")
          .map(abs => ({
            id: abs.id,
            type: abs.type,
            date: abs.date,
            observations: abs.observations,
            _action: abs._action
          }))
      }));
    },

    pending() {
      return this.absences.filter(a => a._action != null);
    },

    pendingCounters() {
      let retval = {
        create: 0,
        update: 0,
        delete: 0
      };

      this.pending.forEach(p => {
        switch (p._action) {
          case "create":
            retval.create++;
            break;
          case "update":
            retval.update++;
            break;
          case "delete":
            retval.delete++;
            break;
        }
      });

      return retval;
    },

    storageKey() {
      let strDate = `${this.date.getDate()}-${this.date.getMonth() +
        1}-${this.date.getFullYear()}`;
      return `attendance:${this.context}:${this.contextId}:absences:${strDate}`;
    }
  },

  watch: {
    context() {
      this.fetchAbsenceTypes();
    },

    contextId() {
      this.fetchPeople();
    },

    date() {
      this.fetchAbsences();
    }
  },

  mounted() {
    this.fetchAbsenceTypes();
    this.fetchPeople();
    this.fetchAbsences();

    this.$emit("update:helper", this);
  },

  methods: {
    async fetchAbsenceTypes() {
      let storageKey = `attendance:${this.context}:absenceTypes`;
      let stored = JSON.parse(localStorage.getItem(storageKey));
      if (Array.isArray(stored)) {
        this.absenceTypes = stored;
      }

      try {
        this.absenceTypes = await this.$api.getAbsenceTypes(this.context);
        localStorage.setItem(storageKey, JSON.stringify(this.absenceTypes));
      } catch (e) {
        // zzzzz
      }
    },

    async fetchPeople() {
      let storageKey = `attendance:${this.context}:${this.contextId}:people`;
      let stored = JSON.parse(localStorage.getItem(storageKey));
      if (Array.isArray(stored)) {
        this.people = stored;
      }

      try {
        this.people = await this.$api.getPeople(this.context, this.contextId);
        localStorage.setItem(storageKey, JSON.stringify(this.people));
      } catch (e) {
        // zzzzz
      }
    },

    async fetchAbsences() {
      let storedAbsences = JSON.parse(localStorage.getItem(this.storageKey));
      if (!Array.isArray(storedAbsences)) {
        storedAbsences = [];
      }
      this.absences = JSON.parse(JSON.stringify(storedAbsences)); // clone stored

      try {
        // fetch absences from API
        let incomingAbsences = await this.$api.getAbsences(
          this.context,
          this.contextId,
          { date: this.date }
        );
        incomingAbsences.forEach(a => (a._action = null));

        // Append (merge) absences from local storage
        storedAbsences
          .filter(a => a._action != null || a.type == "-")
          .forEach(stored => {
            let foundIndex = incomingAbsences.findIndex(a => a.id == stored.id);
            if (foundIndex >= 0) {
              Object.assign(incomingAbsences[foundIndex], stored);
            } else {
              incomingAbsences.push(stored);
            }
          });

        this.absences = incomingAbsences;
        this.persist();
      } catch (e) {
        // zzzzz
      }
    },

    absencesChanged() {
      this.persist();
      this.flush();
    },

    persist() {
      localStorage.setItem(this.storageKey, JSON.stringify(this.absences));
    },

    deletePending() {
      if (!confirm("Eliminar todos los cambios no guardados ?")) {
        return false;
      }

      let deleteTargetIds = [];

      this.absences.forEach((absence, index) => {
        if (!absence._action) {
          return;
        }

        if (absence._action == "update" && absence._original) {
          // this.absences[index] = Object.assign({}, absence._original);
          this.$set(this.absences, index, Object.assign({}, absence._original));
        }

        if (absence._action == "create") {
          deleteTargetIds.push(absence.id);
        }

        absence._action = null;
      });

      deleteTargetIds.forEach(targetId =>
        this.absences.splice(this.absences.findIndex(a => a.id == targetId), 1)
      );

      // localStorage.removeItem(this.storageKey);
      this.persist();
      this.dialogIsOpen = false;
    },

    async eventAdded(evt) {
      let incomingAbsence = {
        id: "tmp:" + Math.round(Math.random() * 10000000),
        person: evt.person,
        type: evt.type,
        observations: evt.observations,
        date: Math.floor(this.date.getTime() / 1000),
        section: this.context == "section" ? this.contextId : undefined,
        session: this.context == "session" ? this.contextId : undefined,
        _action: "create"
      };

      this.absences.push(incomingAbsence);

      this.absencesChanged();
    },

    eventChanged(evt) {
      let i = this.absences.findIndex(abs => abs.id == evt.id);
      if (i < 0) {
        return;
      }

      if (!this.absences[i]._original) {
        this.absences[i]._original = JSON.parse(
          JSON.stringify(this.absences[i])
        );
      }

      this.absences[i]._action = "update";
      this.absences[i].type = evt.type;
      this.absences[i].observations = evt.observations;

      if (this.absences[i].id.substring(0, 4) == "tmp:") {
        this.absences[i]._action = "create";
        this.absences[i]._original = null;
      }

      console.log("eventChanged", evt, this.absences[i], this.pending);

      this.absencesChanged();
    },

    eventRemoved(evt) {
      let i = this.absences.findIndex(abs => abs.id == evt.id);
      if (i < 0) {
        return;
      }

      this.absences[i]._action = "delete";

      if (this.absences[i].id.toString().substring(0, 4) == "tmp:") {
        this.absences.splice(i, 1);
      }

      this.absencesChanged();
    },

    showPending() {
      if (!this.pending.length) {
        return;
      }

      this.dialogIsOpen = true;
    },

    async flush(alertErrors = false) {
      this.dialogIsOpen = false;

      if (!this.pending.length) {
        console.log("flush(): no pending changes");
        return;
      }

      try {
        let response = await this.$api.bulk(this.pending);
        this.isOnline = true;

        response.forEach(item => {
          let foundItem = null;

          switch (item._result) {
            case "deleted":
              let foundIndex = this.absences.findIndex(a => a.id == item.id);
              if (foundIndex < 0) {
                return;
              }

              if (item.type == "-") {
                this.absences[foundIndex]._action = null;
              } else {
                this.absences.splice(foundIndex, 1);
              }
              break;

            case "created":
              foundItem = this.absences.find(a => a.id == item._tmpId);
              if (!foundItem) {
                return;
              }

              foundItem.id = item.id;
              foundItem._action = null;
              break;

            default:
              foundItem = this.absences.find(a => a.id == item.id);
              if (!foundItem) {
                return;
              }
              foundItem._action = null;
              break;
          }
        });

        this.persist();
      } catch (e) {
        this.isOnline = false;
        if (alertErrors) {
          alert("Error sincronizando datos");
        }
      }
    }
  }
};
</script>